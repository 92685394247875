import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TableFilter, UseTableFilters } from '../types/shared-types';

const FILTER_KEYS = [
  'page',
  'size',
  'status',
  'status__in',
  'product_category__in',
  'start_date',
  'end_date',
  'date_filed__gte',
  'date_filed__lte',
  'date__gte',
  'date__lte',
  'order_by',
  'state_code',
  'country_code',
  'country',
  'state',
  'state_name',
  'source__in',
  'has_postal_code',
  'has_city',
  'has_county',
  'has_country',
  'has_state',
  'address_not_empty',
  'customer_id',
  'transaction_id',
  'search_query',
  'filing_id',
  'transaction_type',
  'address_status__in',
  'transaction_source',
  'query',
  'marketplace',
  'jurisdiction',
];
export const DEFAULT_PAGE_SIZE = 25;

export const useTableFilters = (initialFilters: UseTableFilters = { page: 1, size: DEFAULT_PAGE_SIZE }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getValidParam = useCallback(
    (key: string, defaultValue: UseTableFilters[keyof UseTableFilters]) => {
      const param = searchParams.get(key);

      // handling page and size values
      if (['page', 'size'].includes(key)) {
        const parsed = param ? parseInt(param, 10) : defaultValue;
        return Number.isNaN(parsed) ? defaultValue : parsed;
      }

      // handling array values
      if (Array.isArray(defaultValue)) {
        return param ?? defaultValue.join(',');
      }

      // handling boolean values
      if (
        [
          'has_postal_code',
          'has_city',
          'has_county',
          'has_country',
          'has_state',
          'address_not_empty',
          'marketplace',
        ].includes(key)
      ) {
        switch (param) {
          case 'true':
            return true;
          case 'false':
            return false;
          default:
            return defaultValue;
        }
      }
      return param ?? defaultValue;
    },
    [searchParams]
  );

  const validFilters = useMemo(() => {
    const filters = FILTER_KEYS.reduce((acc, key) => {
      (acc[key as keyof UseTableFilters] as any) = getValidParam(key, initialFilters[key as keyof UseTableFilters]);
      return acc;
    }, {} as TableFilter);
    return {
      ...filters,
      page: filters.page ?? 1,
      size: filters.size ?? 25,
    };
  }, [getValidParam, initialFilters]);

  const setFilters = useCallback(
    (newFilters: UseTableFilters = {}) => {
      setSearchParams(params => {
        const setPageAndSize = (page: string, size: string) => {
          params.set('page', page);
          params.set('size', size);
        };

        setPageAndSize(newFilters.page?.toString() ?? '1', newFilters.size?.toString() ?? '25');

        Object.entries(newFilters).forEach(([key, value]) => {
          if (key !== 'page' && key !== 'size') {
            if (value && (typeof value === 'string' || (Array.isArray(value) && value.length > 0))) {
              params.set(key, Array.isArray(value) ? value.join(',') : value);
              setPageAndSize(
                initialFilters.page ? String(initialFilters.page) : '1',
                initialFilters.size ? String(initialFilters.size) : '25'
              );
            } else {
              params.delete(key);
            }
          }
        });

        return params;
      });
    },
    [setSearchParams]
  );

  const resetFilters = useCallback(() => {
    setSearchParams(params => {
      Object.keys(validFilters).forEach(key => params.delete(key));
      return params;
    });
  }, [setSearchParams, validFilters]);

  return { ...validFilters, setFilters, resetFilters };
};

export type UseTableFiltersType = ReturnType<typeof useTableFilters>;
