import { Flex, Td, Text, Tr } from '@chakra-ui/react';
import MenuDropDown from 'component-library/components/Menu/MenuDropDown';
import MenuItem from 'component-library/components/Menu/MenuItem';
import { TableRowSkeleton } from 'component-library/components/Skeleton/table-row-skeleton';
import KSTooltip from 'component-library/components/Tooltiptmp/tooltip';
import { KDataTable } from 'components/table/data-table';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { useState } from 'react';
import { MdMoreVert } from 'react-icons/md';
import { PHYSICAL_NEXUS_CATEGORIES_MAP } from 'types/nexus';
import { PhysicalNexusInstance } from 'types/shared-types';
import { getCountryDisplay } from 'utils';
import { toDateShort } from 'utils/dates';

import { RemovePhysicalNexus } from './remove-physical-nexus-modal';

type PhysicalNexusTableProps = {
  data: PhysicalNexusInstance[];
  isTransactionAvailable: boolean;
  onEdit: (physicalNexus: PhysicalNexusInstance) => void;
  isPending: boolean;
};

const TABLE_HEAD = ['Country', 'Jurisdiction', 'Category', 'Start Date', 'End Date'];
const COLUMN_WIDTHS = {
  Country: '80px',
  Jurisdiction: '100px',
  Category: '220px',
  'Start Date': '100px',
  'End Date': '100px',
};

export const PhysicalNexusTable = ({ data, isTransactionAvailable, onEdit, isPending }: PhysicalNexusTableProps) => {
  const isDataEmpty = !data || data.length === 0;

  if (isDataEmpty && !isPending) {
    return (
      <TableEmptyState
        tableName="Presence"
        uniqueHelperTxt="Please add a presence or connect your payroll system to manage your presence details."
      />
    );
  }

  return (
    <KDataTable headers={TABLE_HEAD} columnWidths={COLUMN_WIDTHS} isPending={isPending}>
      {data.map((row: PhysicalNexusInstance) => {
        return (
          <TableRow
            key={row.state_code}
            physicalNexus={row}
            isTransactionAvailable={isTransactionAvailable}
            onEdit={onEdit}
          />
        );
      })}
      {isPending && <TableRowSkeleton length={25} columns={TABLE_HEAD} />}
    </KDataTable>
  );
};

type TableRowProps = {
  physicalNexus: PhysicalNexusInstance;
  isTransactionAvailable: boolean;
  onEdit: (physicalNexus: PhysicalNexusInstance) => void;
};

const TableRow = ({ physicalNexus, isTransactionAvailable, onEdit }: TableRowProps) => {
  const [confirmRemovePopup, setConfirmRemovePopup] = useState<{ id: string; name: string } | null>(null);
  const isPresenceNonEditable = isTransactionAvailable && !!physicalNexus.end_date;

  return (
    <>
      <Tr>
        <Td width={{ sm: '80px' }}>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{getCountryDisplay(physicalNexus.country_code)}</Text>
          </Flex>
        </Td>
        <Td width={{ sm: '100px' }}>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{physicalNexus.state_code}</Text>
          </Flex>
        </Td>
        <Td width={{ sm: '220px' }}>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{PHYSICAL_NEXUS_CATEGORIES_MAP[physicalNexus.category].title}</Text>
          </Flex>
        </Td>
        <Td width={{ sm: '100px' }}>
          <Flex alignItems={'center'} justifyContent={'space-between'}>
            <Text>{toDateShort(physicalNexus.start_date)}</Text>
          </Flex>
        </Td>
        <Td width={{ sm: '100px' }}>
          <Flex alignItems={'center'} justifyContent={'space-between'}>
            <Text>{toDateShort(physicalNexus.end_date)}</Text>
            <MenuDropDown
              trigger={<MdMoreVert size={'20px'} />}
              itemList={[
                <KSTooltip
                  key="edit-tooltip"
                  label={isPresenceNonEditable ? 'Please contact support to edit presence' : ''}
                >
                  <MenuItem
                    key="edit-menu-item"
                    color={'secondary.500'}
                    isDisabled={isPresenceNonEditable}
                    onClick={() => onEdit(physicalNexus)}
                  >
                    Edit
                  </MenuItem>
                </KSTooltip>,
                <KSTooltip
                  key="remove-tooltip"
                  label={isTransactionAvailable ? 'Please contact support to remove presence' : ''}
                >
                  <MenuItem
                    key="remove-menu-item"
                    isDisabled={isTransactionAvailable}
                    onClick={() => {
                      setConfirmRemovePopup({ id: physicalNexus.id, name: physicalNexus.state_code });
                    }}
                    color={'red.500'}
                  >
                    Remove
                  </MenuItem>
                </KSTooltip>,
              ]}
            />
          </Flex>
        </Td>
      </Tr>
      {confirmRemovePopup?.id && (
        <RemovePhysicalNexus
          physicalNexusId={confirmRemovePopup.id}
          stateName={confirmRemovePopup.name}
          onClose={() => {
            setConfirmRemovePopup(null);
          }}
        />
      )}
    </>
  );
};
