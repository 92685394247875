import { Skeleton, Td, Tr } from '@chakra-ui/react';

type TableRowSkeletonProps = {
  length?: number;
  columns?: string[];
  showEmptyColumn?: boolean;
};
export const TableRowSkeleton = ({ length = 10, columns = [], showEmptyColumn = false }: TableRowSkeletonProps) => {
  return (
    <>
      {Array.from({ length }, (_, index) => (
        <Tr height="55px" key={index}>
          {columns.map(col => (
            <Td key={col}>
              <Skeleton width="full" h="20px" />
            </Td>
          ))}
          {showEmptyColumn && (
            <Td>
              <Skeleton minWidth="105px" h="20px" />
            </Td>
          )}
        </Tr>
      ))}
    </>
  );
};
