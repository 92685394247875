import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys);

const beta = definePartsStyle({
  container: {
    bg: 'secondary.500',
    color: 'white',
    borderRadius: '2px',
    px: '2',
    py: '0',
    minH: '5',
    fontSize: 'xxs',
    fontWeight: 'bold',
  },
});

export const tagTheme = defineMultiStyleConfig({
  variants: {
    beta,
  },
});
