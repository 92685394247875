import { Flex, Spinner } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { getPhysicalMailOnboardingLink } from 'apis/organizations-apis';
import Text from 'component-library/components/Text';
import { useOrg } from 'hooks/useOrg';
import { useEffect } from 'react';

export const StableForm = () => {
  const { orgId } = useOrg();
  const { mutate, isError } = useMutation({
    mutationFn: () => {
      return getPhysicalMailOnboardingLink(orgId);
    },
    onSuccess: (response: any) => {
      const link = document.createElement('a');
      link.href = response.data;
      document.body.appendChild(link);
      link.click();
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      mutate();
    }, 3000);

    return () => clearTimeout(timer);
  }, [mutate]);

  if (isError) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Text fontSize="2xl" fontWeight="bold">
          Error loading stable form
        </Text>
      </Flex>
    );
  }

  return (
    <Flex justifyContent="center" alignItems="center" height="100vh">
      <Text fontSize="2xl" fontWeight="bold">
        Redirecting in a few seconds...
      </Text>
      <Spinner />
    </Flex>
  );
};
