import {
  ProductCategoryRead,
  ProductCategorySubcategoryOverview,
  ProductCategoryUpdate,
  ProductConfigsCreateType,
  ProductConfigsType,
  ProductCreate,
  ProductSummaryRead,
  ProductUpdate,
} from 'types/products';
import { ApiResponse, ProductInstance, TableFilter } from 'types/shared-types';

import api from './api';

const getProductById = (product_id: string, orgId: string) => {
  return api.get(`/v1/products/${product_id}`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getProducts = ({ orgId, params }: { orgId: string; params?: TableFilter }) => {
  return api.get<ApiResponse<ProductInstance>>(`/v1/products/`, {
    headers: {
      'x-organization-id': orgId,
    },
    params,
  });
};

const createProduct = (orgId: string, payload: ProductCreate) => {
  return api.post(`/v1/products/`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const updateProducts = (product_id: string, orgId: string, payload: ProductUpdate) => {
  return api.put(`/v1/products/${product_id}`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getProductCategories = (orgId: string) => {
  return api.get<ProductCategoryRead[]>(`/v1/products/categories/`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const bulkClassifyProducts = (orgId: string) => {
  return api.post(`/v1/products/bulk_classify/`, null, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const bulkApproveProducts = (orgId: string, payload: any) => {
  return api.put(`/v1/products/bulk_approve/`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const updateProductsClassificationConfigs = (orgId: string, payload: any) => {
  return api.put(`/v1/products/configs`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const doProductsClassificationConfigs = (orgId: string, payload: ProductConfigsCreateType) => {
  return api.post(`/v1/products/configs`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getProductClassificationConfigs = (orgId: string) => {
  return api.get<ProductConfigsType[]>(`/v1/products/configs`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getProductClassificationStatus = (orgId: string) => {
  return api.get(`/v1/products/classification_status/`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const exportProducts = (orgId: string) => {
  return api.post(`/v1/products/reports/export`, null, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getProductsSummary = (orgId: string) => {
  return api.get<ProductSummaryRead>(`/v1/products/summary`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getProductCategorySubcategoriesOverview = (orgId: string) => {
  return api.get<ProductCategorySubcategoryOverview[]>(`/v1/products/overview`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const updateProductCategorySubcategory = (orgId: string, payload: ProductCategoryUpdate) => {
  return api.put(`/v1/internal/products/categories`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const PRODUCT_STATE_KEY = 'product';
const PRODUCT_CATEGORIES_STATE_KEY = 'product-categories';
const PRODUCT_CLASSIFICATION_STATE_KEY = 'product-classification';
const PRODUCT_SUMMARY_STATE_KEY = 'product-sources';
const PRODUCT_CATEGORY_SUBCATEGORIES_OVERVIEW_STATE_KEY = 'product-category-subcategories-overview';

export {
  bulkApproveProducts,
  bulkClassifyProducts,
  createProduct,
  doProductsClassificationConfigs,
  exportProducts,
  getProductById,
  getProductCategories,
  getProductCategorySubcategoriesOverview,
  getProductClassificationConfigs,
  getProductClassificationStatus,
  getProducts,
  getProductsSummary,
  PRODUCT_CATEGORIES_STATE_KEY,
  PRODUCT_CATEGORY_SUBCATEGORIES_OVERVIEW_STATE_KEY,
  PRODUCT_CLASSIFICATION_STATE_KEY,
  PRODUCT_STATE_KEY,
  PRODUCT_SUMMARY_STATE_KEY,
  updateProductCategorySubcategory,
  updateProducts,
  updateProductsClassificationConfigs,
};
