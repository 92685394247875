export type LocationOptionType = {
  value: string;
  label: string;
  id?: number;
};

export type UseLocationDataType = {
  countries: LocationOptionType[];
  getStates: (countryCode: string) => Promise<LocationOptionType[]>;
  getCities: (countryCode: string, stateCode: string) => Promise<LocationOptionType[]>;
  isLoadingCountries: boolean;
  error: Error | null;
};

/**
 * Canada Tax Jurisdictions.
 *
 * Used for nexus, registrations and filing.
 */
export enum CanadaTaxJurisdictionEnum {
  BRITISH_COLUMBIA = 'BC',
  FEDERAL = 'FD',
  MANITOBA = 'MB',
  QUEBEC = 'QC',
  SASKATCHEWAN = 'SK',
}

/**
 * Canada Tax Exemption Jurisdictions.
 *
 * Used for tax exemptions
 */
export enum CanadaTaxExemptionJurisdictionEnum {
  BRITISH_COLUMBIA = 'BC',
  MANITOBA = 'MB',
  QUEBEC = 'QC',
  SASKATCHEWAN = 'SK',
}

/**
 * Canada Provinces.
 *
 * Used for address and other purposes
 */
export enum CanadaProvinceEnum {
  ALBERTA = 'AB',
  BRITISH_COLUMBIA = 'BC',
  MANITOBA = 'MB',
  NEW_BRUNSWICK = 'NB',
  NORTHWEST_TERRITORIES = 'NT',
  NEWFOUNDLAND_AND_LABRADOR = 'NL',
  NOVA_SCOTIA = 'NS',
  NUNAVUT = 'NU',
  ONTARIO = 'ON',
  PRINCE_EDWARD_ISLAND = 'PE',
  QUEBEC = 'QC',
  SASKATCHEWAN = 'SK',
  YUKON = 'YT',
}
