import { ApideckVault, Connection } from '@apideck/vault-js';
import { useMutation } from '@tanstack/react-query';
import { createApiDeckConnection, deleteApiDeckConnection, updateApiDeckConnections } from 'apis/connections';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useTracking from 'hooks/useTracking';
import { useState } from 'react';

import { ItemCard } from '../item-card';
import { WooCommerceIcon } from './icons/woocommerce';

export default function WooCommerceTab({ isConnected = false }: { isConnected?: boolean }) {
  const { orgId } = useOrg();
  const { track } = useTracking();
  const { handleFailNotification } = useHandleNotification();

  const [connection, setConnection] = useState({
    connectionId: '',
  });

  const { mutateAsync: doUpdateApideckConnections, isPending } = useMutation({
    mutationFn: (status: string) => {
      return updateApiDeckConnections(connection.connectionId, orgId, status);
    },
    onSuccess: () => {
      track('connects integration', { integration: 'woocommerce' });
    },
    onError: error => {
      handleFailNotification(error);
    },
  });

  const handleConnectionChange = async (connection: Connection) => {
    if (connection.state === 'callable') {
      await doUpdateApideckConnections('ACTIVE');
    }
    if (connection.state === 'added') {
      await doUpdateApideckConnections('INACTIVE');
    }
  };

  const { mutate: doCreateApideckConnection, isPending: isCreating } = useMutation({
    mutationFn: async () => {
      const { data } = await createApiDeckConnection(orgId, 'woocommerce', {});
      return data;
    },
    onSuccess: data => {
      const token = data['token'];
      const connectionId = data['connection_id'];
      setConnection(prev => ({ ...prev, connectionId }));
      ApideckVault.open({
        token: token,
        unifiedApi: 'ecommerce',
        serviceId: 'woocommerce',
        onConnectionChange: handleConnectionChange,
        onClose: () => deleteApiDeckConnection(connectionId, orgId),
      });
    },
    onError: error => {
      handleFailNotification(error);
    },
  });

  return (
    <ItemCard
      title="WooCommerce"
      description="Open-source eCommerce toolkit."
      isConnected={isConnected}
      icon={<WooCommerceIcon />}
      onConnect={doCreateApideckConnection}
      btnProps={{ isLoading: isCreating || isPending }}
      installationGuide="https://trykintsugi.com/blog/integrate-kintsugi-with-woo-commerce-a-seamless-fusion-in-minutes"
    />
  );
}
