import { useEffect, useState } from 'react';
import { getHumanReadableString } from 'utils/enum-helpers';

type Item = Record<string, any>;

type UseCustomSelectType = {
  items: Item[];
  itemTitle?: string;
  itemValue?: string;
  value?: string | number | Item;
  returnItem?: boolean;
};

type UseCustomSelectReturn = {
  getTitle: (item: Item) => string;
  getValue: (item: Item) => Item | string | number;
  selectedValue: string | number | Item | undefined;
  isItemSelected: (item: Item) => boolean;
  getTitleFromValue: () => string;
};

export const useCustomSelect = ({
  items,
  itemTitle,
  itemValue,
  value,
  returnItem,
}: UseCustomSelectType): UseCustomSelectReturn => {
  const [selectedValue, setSelectedValue] = useState<string | number | Item>();

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const getTitle = (item: any) => {
    const isObject = typeof item === 'object';
    return isObject ? item[itemTitle as string] : item;
  };

  const getValue = (item: any) => {
    if (typeof item === 'object') {
      if (returnItem) {
        setSelectedValue(item);
        return item;
      } else {
        const value = item[itemValue as string];
        setSelectedValue(value);
        return value;
      }
    }
    return item;
  };

  const getTitleFromValue = () => {
    if (returnItem && typeof selectedValue === 'object') {
      return getHumanReadableString(selectedValue[itemTitle as string]);
    }

    const foundItem = items?.find((item: Item) => item[itemValue as string] === selectedValue);
    if (foundItem && typeof foundItem === 'object') {
      return getHumanReadableString(foundItem[itemTitle as string]);
    }

    return '';
  };

  const isItemSelected = (item: Item) => {
    if (!selectedValue) {
      return false;
    }
    return selectedValue === item[itemValue as string];
  };

  return {
    getTitle,
    getTitleFromValue,
    getValue,
    selectedValue,
    isItemSelected,
  };
};
