import { Flex, FormControl, Icon, Link, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CONNECTIONS_STATE_KEY, createStripe } from 'apis/connections';
import { useOrgDetailsQuery } from 'apis/organizations-apis';
import Button from 'component-library/components/ButtonTmp/button';
import FormLabel from 'component-library/components/FormLabel';
import { KInput, KSmartInput } from 'component-library/components/Input';
import ModalPopup from 'component-library/components/Modal/modal-popup';
import Text from 'component-library/components/Text';
import Tooltip from 'component-library/components/Tooltiptmp/tooltip';
import { InfoIconFilled } from 'components/icons';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { object, string } from 'yup';

import { BusinessAddressPrompt } from '../../update-address-prompt';
import { ItemCard } from '../item-card';
import { StripeIcon } from './icons/stripe';

// const STRIPE_APP_URL = import.meta.env.VITE_STRIPE_MARKETPLACE_URL;

const stripeValidationSchema = object().shape({
  publishable_key: string(),
  api_key: string().test(
    'match',
    `Please ensure you're using a matching secret key and publishable key`,
    function (value) {
      if (!value) {
        return false;
      }
      return (
        (value.includes('test') && this.parent?.publishable_key?.includes('test')) ||
        (value.includes('live') && this.parent?.publishable_key?.includes('live'))
      );
    }
  ),
});

const StripeConnectModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const { orgId, isTest } = useOrg();
  const { handleSuccessNotification } = useHandleNotification();
  const queryClient = useQueryClient();

  const [, setSearchParams] = useQueryParam('liveSource');
  const navigate = useNavigate();

  const stripeFormik = useFormik({
    initialValues: {
      publishable_key: '',
      api_key: '',
    },
    validationSchema: stripeValidationSchema,
    onSubmit: values => {
      stripeMutation.mutate(values);
    },
  });

  const stripeMutation = useMutation({
    mutationFn: (payload: { publishable_key: string; api_key: string }) => {
      return createStripe(orgId, payload.publishable_key, { api_key: payload.api_key });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CONNECTIONS_STATE_KEY] });
      onClose();
      handleSuccessNotification('Stripe connected Successfully.');
      navigate('/source');
    },
  });

  const handlePatternMatched = () => {
    stripeFormik.resetForm();
    onClose();
    setSearchParams({ liveSource: 'stripe' });
  };

  return (
    <ModalPopup
      scrollBehavior="inside"
      closeOnOverlayClick={false}
      size={'lg'}
      isOpen={isOpen}
      onClose={onClose}
      header="Connect Stripe"
      footer={
        <Flex gap={2}>
          <Button variant={'outline'} color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={stripeMutation.isPending}
            variant="solid"
            color="primary"
            width={'90px'}
            onClick={() => stripeFormik.handleSubmit()}
            isDisabled={!(stripeFormik.isValid && stripeFormik.dirty)}
          >
            Save
          </Button>
        </Flex>
      }
    >
      <FormControl>
        <FormLabel htmlFor="publishable_key" color={'#262B47'}>
          Stripe Publishable Key
        </FormLabel>
        <KInput
          id="publishable_key"
          name="publishable_key"
          value={stripeFormik.values.publishable_key}
          onChange={stripeFormik.handleChange}
        />
        {stripeFormik.errors.publishable_key && (
          <Text mt={1} color={'red.500'}>
            {stripeFormik.errors.publishable_key}
          </Text>
        )}
      </FormControl>
      <FormControl>
        <FormLabel color={'#262B47'} htmlFor="api_key">
          <Flex direction={'row'} gap={2} align="center" mt={2}>
            Stripe Secret Key
            <Tooltip
              shouldWrapChildren
              closeDelay={5000}
              closeOnPointerDown
              pointerEvents="all"
              backgroundColor={'#262B47'}
              label={
                <Flex direction="column" w="100%" alignItems="center">
                  <Text color="fffff" fontWeight={'400'} size={'14px'} lineHeight={'20px'} text-align="center">
                    You can find the instructions to get the keys
                  </Text>
                  <span>
                    <Link
                      textDecoration={'underline'}
                      target="_blank"
                      href="https://kintsugi.readme.io/docs/connect-stripe-to-kintsugi-organization"
                      rel="noreferrer"
                    >
                      here
                    </Link>
                  </span>
                </Flex>
              }
            >
              <Icon mt={2} fontSize={'10px'} as={InfoIconFilled} alignItems="center" />
            </Tooltip>
          </Flex>
        </FormLabel>
        <KSmartInput
          id="api_key"
          name="api_key"
          value={stripeFormik.values.api_key}
          onChange={stripeFormik.handleChange}
          shouldMatchPattern={isTest}
          pattern={'^sk_live_\\w*$'}
          onPatternMatched={handlePatternMatched}
        />
        {stripeFormik.errors.api_key && (
          <Text mt={1} color={'red.500'}>
            {stripeFormik.errors.api_key}
          </Text>
        )}
      </FormControl>
    </ModalPopup>
  );
};

export default function StripeTab({ isConnected = false }: { isConnected?: boolean }) {
  const { orgId } = useOrg();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isBusinessAddressPromptOpen, setIsBusinessAddressPromptOpen] = useState(false);

  const { data } = useOrgDetailsQuery(orgId);
  const isAddressMissing = data ? !data.company_country_code || !data.company_postal_code : true;
  // const openInNewTab = () => {
  //   window.open(STRIPE_APP_URL, '_blank', 'noopener,noreferrer');
  // };

  const handleOpenStripeModal = () => {
    setIsBusinessAddressPromptOpen(false);
    onOpen();
  };

  // const handleOnOpen = () => {
  // if (isTest) {
  // handleOpenStripeModal();
  // } else {
  //   openInNewTab();
  // }
  // };

  // const handleConnect = () => {
  //   setIsBusinessAddressPromptOpen(false);

  //   // if (isTest) {
  //   handleOpenStripeModal();
  //   // } else {
  //   //   openInNewTab();
  //   // }
  // };

  return (
    <>
      <ItemCard
        isConnected={isConnected}
        title={'Stripe'}
        description={'Financial Infrastructure for the Internet'}
        icon={<StripeIcon />}
        onConnect={() => (isAddressMissing ? setIsBusinessAddressPromptOpen(true) : onOpen())}
        installationGuide="https://trykintsugi.com/blog/how-to-integrate-kintsugi-sales-tax-automation-with-stripe-a-step-by-step-guide"
      />
      <BusinessAddressPrompt
        isOpen={isBusinessAddressPromptOpen}
        onClose={() => setIsBusinessAddressPromptOpen(false)}
        onSuccess={handleOpenStripeModal}
      />
      <StripeConnectModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}
