import { Icon, ListItem, Stack, UnorderedList } from '@chakra-ui/react';
import Text from 'component-library/components/Text';
import Tooltip from 'component-library/components/Tooltiptmp/tooltip';
import { MdInfo } from 'react-icons/md';

export const PhysicalNexusHeaderLevel = () => {
  return (
    <Text fontSize={'20px'} fontWeight="500" display={'inline-flex'} gap={2} alignItems={'center'}>
      Presence
      <Tooltip
        shouldWrapChildren
        label={
          <Stack>
            <Text color={'white'}>
              {`A "Physical Nexus," in the context of business and taxation, refers to a substantial physical presence or connection that a business entity has in a particular jurisdiction.`}
            </Text>
            <Stack spacing={0}>
              <Text color={'white'}>Physical nexus states on our platform include:</Text>
              <UnorderedList ml={4}>
                <ListItem>Physical stores</ListItem>
                <ListItem>Offices</ListItem>
                <ListItem>Mailing addresses</ListItem>
                <ListItem>Warehouses</ListItem>
                <ListItem>
                  Employees living in the state or having regular presence (include remote employees and contractors)
                </ListItem>
                <ListItem>List of states where inventory is stored</ListItem>
                <ListItem>Event or trade show attendance states</ListItem>
              </UnorderedList>
            </Stack>
          </Stack>
        }
      >
        <Icon color="#4B5169" fontSize={'20px'} as={MdInfo} display="inherit" />
      </Tooltip>
    </Text>
  );
};
