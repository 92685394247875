import { popoverAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  content: {
    p: 4,
    boxShadow: '0px 8px 20px -4px #1718181F',
    border: 'none',
    bg: '#FFFFFF',
  },
  footer: {
    px: 4,
    paddingBottom: 4,
  },
});

const beta = definePartsStyle({
  content: {
    backgroundColor: 'gray.900',
    borderRadius: '2px',
    color: 'white',
  },
  body: {
    padding: 0,
    p: {
      color: 'white',
    },
  },
});

export const popoverTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    beta,
  },
});
