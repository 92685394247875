import { BillingPlan } from 'types/billings';

export const BILLING_PLANS = [
  {
    plan: 'GROWTH',
    title: 'Growth',
    price: '$100/State *',
    features: [
      'Everything in Free',
      'Limited to 10 actions per month',
      'Faster data sync',
      'Filing and registrations',
      '50 TaxGPT questions',
      'Deregistration',
      'Refunds and amendments',
      'Email support',
      'Reporting',
    ],
    info: 'State where you need to register or file',
  },
  {
    plan: 'PREMIUM',
    title: 'Premium',
    price: 'Starting at $1500/month',
    features: [
      'Everything in Growth',
      'For companies in 10+ states',
      'Unlimited actions',
      'Unlimited TaxGPT questions',
      'Platform (APIs, premium connectors)',
      'CPA on demand',
      'Filing autopilot',
      'Slack support & SLAs',
      'Error insurance',
      'SSO & advanced reporting',
      'Voluntary Disclosure Agreement',
    ],
  },
] satisfies BillingPlan[];

export const GROWTH_STATE_USAGES_THRESHOLD: number = 10;
