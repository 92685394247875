import { Badge, Card, Flex, IconButton, Link, VStack } from '@chakra-ui/react';
import Button, { CustomButtonProps } from 'component-library/components/ButtonTmp/button';
import Text from 'component-library/components/Text';
import Tooltip from 'component-library/components/Tooltiptmp/tooltip';
import { OpenNewWindowIcon } from 'components/icons';

type ItemCardProps = {
  title: string;
  description: string;
  icon: React.ReactNode;
  onConnect: () => void;
  isConnected: boolean;
  btnProps?: CustomButtonProps;
  installationGuide?: string;
};

export const ItemCard = ({
  title,
  description,
  icon,
  isConnected = false,
  onConnect,
  btnProps = {},
  installationGuide,
}: ItemCardProps) => {
  return (
    <Card
      p={4}
      gap={4}
      border={'1px #CFD0D8 solid'}
      borderRadius={'4px'}
      justify={'space-between'}
      align={'flex-start'}
      width={'400px'}
    >
      <Flex justify={'space-between'} width={'100%'}>
        {icon}
        <Tooltip
          label="The installation guide will be available soon. Contact support for assistance."
          hasArrow={false}
          isDisabled={!!installationGuide}
          placement="bottom-start"
        >
          <Flex height={'22px'} align={'center'} gap={2}>
            <Text color={installationGuide ? 'gray.700' : 'gray.200'} fontSize={'12px'} cursor={'default'}>
              View Installation Guide
            </Text>
            <IconButton
              icon={<OpenNewWindowIcon />}
              as={Link}
              aria-label="View Installation Guide"
              href={installationGuide}
              target="_blank"
              cursor={installationGuide ? 'pointer' : 'default'}
              variant={'transparent-with-icon'}
              minWidth={'12px'}
              opacity={installationGuide ? 1 : 0.5}
            />
          </Flex>
        </Tooltip>
      </Flex>
      <VStack spacing={2} align={'flex-start'}>
        <Text fontSize={'18px'} fontWeight={'500'} gap={2} display={'flex'} alignItems={'center'}>
          {title}
          {isConnected && (
            <Badge variant="green" fontWeight="normal">
              Connected
            </Badge>
          )}
        </Text>
        <Text size={'md'} color={'#4B5169'}>
          {description}
        </Text>
      </VStack>
      <Button color={'secondary'} {...btnProps} onClick={onConnect} width={'100%'}>
        Connect
      </Button>
    </Card>
  );
};
