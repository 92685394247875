/* eslint-disable react-hooks/rules-of-hooks */
import { useAuthInfo } from '@propelauth/react';
import * as Sentry from '@sentry/react';
import { ErrorFallback, withErrorBoundary } from 'components/error-fallback';
import useTracking from 'hooks/useTracking';
import { StableForm } from 'pages/Configuration/components/communications/stable-form';
import { CustomerDetails } from 'pages/Customers/components/customer-details';
import BlankAddressTransactions from 'pages/Dashboard/components/BlankAddressTransactions';
import Integrations from 'pages/Import/components/integrations';
import Presence from 'pages/Presence';
import { TransactionDetails } from 'pages/Transactions/components/transaction-details';
import React, { lazy, useEffect } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import { BLANK_ADDRESSES, PARTNER_ORG, TOOLS_AND_GUIDE } from '../constants/AppRoutes';
import { AppLayout } from './app-layout';

const fallback = () => <ErrorFallback />;
const errorBoundaryOptions = { fallback, onerror: (error: Error) => console.error(error) };
const Dashboard = withErrorBoundary(
  lazy(() => import('pages/Dashboard')),
  errorBoundaryOptions
);
const Settings = withErrorBoundary(
  lazy(() => import('pages/Configuration')),
  errorBoundaryOptions
);
const Customers = withErrorBoundary(
  lazy(() => import('pages/Customers')),
  errorBoundaryOptions
);
const Transactions = withErrorBoundary(
  lazy(() => import('pages/Transactions/transaction')),
  errorBoundaryOptions
);
const Registrations = withErrorBoundary(
  lazy(() => import('pages/Registrations')),
  errorBoundaryOptions
);
const Nexus = withErrorBoundary(
  lazy(() => import('pages/Nexus')),
  errorBoundaryOptions
);
const Filing = withErrorBoundary(
  lazy(() => import('pages/Filing')),
  errorBoundaryOptions
);
const ShopifyLanding = withErrorBoundary(
  lazy(() => import('shopify')),
  errorBoundaryOptions
);
const ConnectShopify = withErrorBoundary(
  lazy(() => import('shopify/ConnectShopify')),
  errorBoundaryOptions
);
const ToolsAndGuide = withErrorBoundary(
  lazy(() => import('pages/ToolsAndGuide')),
  errorBoundaryOptions
);
const Products = withErrorBoundary(
  lazy(() => import('pages/Products')),
  errorBoundaryOptions
);
const Import = withErrorBoundary(
  lazy(() => import('pages/Import')),
  errorBoundaryOptions
);
const Exemptions = withErrorBoundary(
  lazy(() => import('pages/Exemptions')),
  errorBoundaryOptions
);
const BulkCleanupAddresses = withErrorBoundary(
  lazy(() => import('pages/Addresses')),
  errorBoundaryOptions
);
const AppRedirector = withErrorBoundary(
  lazy(() => import('app/app-redirector')),
  errorBoundaryOptions
);
const Supports = withErrorBoundary(
  lazy(() => import('pages/Supports')),
  errorBoundaryOptions
);
const PartnerOrg = withErrorBoundary(
  lazy(() => import('pages/PartnerOrg')),
  errorBoundaryOptions
);
const StripeAppInstaller = withErrorBoundary(
  lazy(() => import('app/stripe-app-installer-with-org')),
  errorBoundaryOptions
);

const AppRoutes = (): React.ReactNode => {
  const { identifyUser } = useTracking();
  const { user } = useAuthInfo();

  useEffect(() => {
    if (user) {
      identifyUser({ email: user.email, id: user.userId });
      Sentry.setUser({ email: user.email, id: user.userId });
    }
  }, [user?.userId]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route index element={<Dashboard />} />
          {/* Redirect from base configurations path to default tab */}
          <Route path="/configurations" element={<Navigate to="/configurations/organization-details" replace />} />
          <Route path="/configurations/:tab?" element={<Settings />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/customers/:customerId" element={<CustomerDetails />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/transactions/:transactionId" element={<TransactionDetails />} />
          <Route path="/nexus" element={<Nexus />} />
          <Route path="/filing" element={<Filing />} />
          <Route path="/registrations" element={<Registrations />} />
          <Route path="/source" element={<Import />} />
          <Route path="/integrations" element={<Integrations />} />
          <Route path="/shopify" element={<ShopifyLanding />} />
          <Route path="/connect/shopify" element={<ConnectShopify />} />
          <Route path={PARTNER_ORG} element={<PartnerOrg />} />
          <Route path="/stableform" element={<StableForm />} />
          <Route path={TOOLS_AND_GUIDE} element={<ToolsAndGuide />} />
          <Route path="/products" element={<Products />} />
          <Route path="/exemptions" element={<Exemptions />} />
          <Route path="/presence" element={<Presence />} />
          <Route path="/invalid-addresses" element={<BulkCleanupAddresses />} />
          <Route path={BLANK_ADDRESSES} element={<BlankAddressTransactions />} />
        </Route>
        <Route path="/supports" element={<Supports />} />
        <Route path="/redirect" element={<AppRedirector />} />
        <Route path="/stripe-app" element={<StripeAppInstaller />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
