import { Checkbox, Flex, Icon, Spinner, Table, TableProps, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import TableContainer from 'component-library/components/Table/table-container';
import React, { ReactNode, useState } from 'react';
import { IconType } from 'react-icons';
import { CheckboxRowProps } from 'types/data-table';

import ColumnFilter from './ColumnFilter';

type KDataTableProps = {
  onboardingTable?: string;
  headers: string[];
  numericCols?: string[];
  centerColumns?: string[];
  sortHeaders?: string[];
  handleSort?: (header: string) => string;
  getSortIcon?: (header: string) => IconType;
  children: ReactNode;
  tableStyle?: TableProps;
  defaultVisibleColumns?: string[];
  showColumnFilter?: boolean;
  getHeaderRightIcon?: (header: string) => ReactNode;
  onVisibleColumnsChange?: (columns: string[]) => void;
  height?: string;
  columnWidths?: Record<string, string>;
  isPending?: boolean;

  // Checkbox props
  enableCheckboxSelection?: boolean;
  checkedRows?: string[];
  onRowCheck?: (selectedIds: string[]) => void;
  getRowId?: (row: any) => string;
  isRowCheckable?: (row: any) => boolean;
};

export const KDataTable = ({
  onboardingTable = '',
  headers,
  numericCols = [],
  tableStyle,
  children,
  defaultVisibleColumns = headers,
  showColumnFilter = false,
  onVisibleColumnsChange,
  centerColumns,
  sortHeaders,
  handleSort,
  getSortIcon,
  getHeaderRightIcon,
  height,
  columnWidths,
  isPending,

  // Checkbox props
  enableCheckboxSelection = false,
  checkedRows = [],
  onRowCheck,
  getRowId,
  isRowCheckable = () => true,
}: KDataTableProps) => {
  const [visibleColumns, setVisibleColumns] = useState<string[]>(defaultVisibleColumns);

  const handleToggleColumn = (columns: string[]) => {
    setVisibleColumns(columns);
    if (onVisibleColumnsChange) {
      onVisibleColumnsChange(columns);
    }
  };

  const visibleHeaders = headers.filter(header => visibleColumns.includes(header));
  const checkboxSelectableRows = enableCheckboxSelection
    ? React.Children.toArray(children)
        ?.filter(React.isValidElement)
        ?.filter((child: React.ReactElement) => isRowCheckable(child.props.data))
    : [];

  const totalCheckboxSelectableRows = checkboxSelectableRows?.length;
  const allSelected = enableCheckboxSelection
    ? checkedRows?.length === totalCheckboxSelectableRows && totalCheckboxSelectableRows > 0
    : false;

  const handleCheckboxSelectAll = () => {
    if (!onRowCheck || !getRowId) return;

    if (allSelected || (checkedRows.length > 0 && checkedRows.length < totalCheckboxSelectableRows)) {
      onRowCheck([]);
    } else {
      const allIds = checkboxSelectableRows.map(child => getRowId((child as React.ReactElement).props.data));
      onRowCheck(allIds);
    }
  };

  return (
    <TableContainer data-onboarding={onboardingTable} height={height} overflowX="visible" position="relative">
      <Table variant="unstyled" {...tableStyle}>
        <Thead>
          <Tr>
            {enableCheckboxSelection && (
              <Th width="40px" padding="0 0 0 16px">
                <Checkbox
                  isChecked={allSelected}
                  isIndeterminate={checkedRows.length > 0 && !allSelected}
                  onChange={handleCheckboxSelectAll}
                />
              </Th>
            )}
            {visibleHeaders.map((header: string, idx: number) => {
              const isSortableCol = sortHeaders?.includes(header);
              return (
                <Th
                  key={`${idx}-${header}`}
                  isNumeric={numericCols.includes(header)}
                  textAlign={centerColumns?.includes(header) ? 'center' : undefined}
                  onClick={() => isSortableCol && handleSort?.(header)}
                  width={{ base: columnWidths?.[header], md: 'auto' }}
                  style={{ minWidth: columnWidths?.[header] }}
                >
                  <Flex
                    justifyContent={numericCols.includes(header) ? 'flex-end' : undefined}
                    alignItems={'center'}
                    cursor={isSortableCol ? 'pointer' : 'default'}
                  >
                    {header}
                    {getHeaderRightIcon?.(header)}
                    {isSortableCol && getSortIcon && <Icon as={getSortIcon(header)} fontSize={'18px'} />}
                  </Flex>
                </Th>
              );
            })}
            {showColumnFilter && (
              <Flex justifyContent={'flex-end'}>
                <ColumnFilter headers={headers} onToggleColumn={handleToggleColumn} visibleColumns={visibleColumns} />
              </Flex>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {enableCheckboxSelection
            ? React.Children.map(children, child => {
                if (React.isValidElement(child)) {
                  return React.cloneElement(child, {
                    enableSelection: true,
                    isSelected: checkedRows.includes(getRowId?.(child.props.data) ?? ''),
                    onRowCheck,
                    getRowId,
                    checkedRows,
                  } as CheckboxRowProps);
                }
                return child;
              })
            : children}
        </Tbody>
      </Table>
      {isPending && (
        <Flex pos="absolute" top="0" right="0" w="full" h="full" bg="white" opacity={0.8} justify="center" zIndex="100">
          <Spinner marginTop={100} size="lg" />
        </Flex>
      )}
    </TableContainer>
  );
};
