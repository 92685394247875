import { useAuthInfo } from '@propelauth/react';
import { usePaywall } from 'app/acl/paywall';
import { useEffect } from 'react';

import { useOrg } from './useOrg';

declare global {
  interface Window {
    posthog: any;
  }

  interface TrackingProperties {
    [key: string]: string | number | boolean | undefined;
  }
}

let posthogAnalytics: any;

type UseTrackingReturn = {
  track: (eventName: string, properties?: TrackingProperties) => void;
  pageLoad: () => void;
  identifyUser: (user: { email: string; id: string }) => void;
  resetAnalytics: () => void;
};

const useTracking = (): UseTrackingReturn => {
  const { isImpersonating, impersonatorUserId } = useAuthInfo();
  const { orgId, name, isTest } = useOrg();
  const { billingPlan } = usePaywall();

  const isPosthogEnabled = import.meta.env.VITE_ENABLE_INSTRUMENTATION;
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Wait for posthog to be available
      const initPosthog = () => {
        if (window.posthog) {
          posthogAnalytics = window.posthog;
        } else {
          // Retry after a short delay if posthog isn't loaded yet
          setTimeout(initPosthog, 100);
        }
      };

      initPosthog();
    }
  }, []);

  const isAnalyticsLibLoaded = (): boolean => {
    if (!isPosthogEnabled) {
      return false;
    }

    if (typeof window === 'undefined' || !window.posthog) {
      return false;
    }

    if (!posthogAnalytics && isPosthogEnabled) {
      console.log('PostHog analytics not loaded properly');
    }

    return Boolean(posthogAnalytics);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const track = (eventName: string, properties?: TrackingProperties): void => {
    if (!isAnalyticsLibLoaded()) {
      return;
    }

    if (posthogAnalytics && isPosthogEnabled) {
      posthogAnalytics.group('company', orgId);
      posthogAnalytics.capture(eventName, {
        organization_id: orgId,
        organization_name: name,
        is_test_org: isTest,
        billing_plan: billingPlan,
        is_impersonating: isImpersonating,
        impersonator_user_id: impersonatorUserId,
        ...(properties || {}),
      });
    }
  };

  const pageLoad = (): void => {
    if (!isAnalyticsLibLoaded()) {
      return;
    }

    if (posthogAnalytics && isPosthogEnabled) {
      posthogAnalytics.capture({
        event: '$pageview',
        groups: {
          company: orgId, // Grouping by company
        },
      });
    }
  };

  const identifyUser = (user: { email: string; id: string }): void => {
    if (!isAnalyticsLibLoaded()) {
      return;
    }

    if (posthogAnalytics && isPosthogEnabled) {
      posthogAnalytics.identify(user.id, {
        email: user.email,
        organization_id: orgId,
        organization_name: name,
        is_test_org: isTest,
        billing_plan: billingPlan,
        is_impersonating: isImpersonating,
        impersonator_user_id: impersonatorUserId,
      });
      posthogAnalytics.group('company', orgId);
    }
  };

  const resetAnalytics = (): void => {
    if (!isAnalyticsLibLoaded()) {
      return;
    }

    if (posthogAnalytics && isPosthogEnabled) {
      posthogAnalytics.reset();
    }
  };

  return { track, pageLoad, identifyUser, resetAnalytics };
};

export default useTracking;
