import { ProductCategoryEnum, ProductStatus, ProductStatusVariant, ProductSubCategoryEnum } from 'types/products';
import { getHumanReadableString } from 'utils/enum-helpers';

export const productStatusVariantMap = {
  APPROVED: {
    variant: 'approved',
    title: 'Approved',
  },
  PENDING: {
    variant: 'pending',
    title: 'Pending',
  },
  PARTIALLY_APPROVED: {
    variant: 'partial',
    title: 'Partially Approved',
  },
} satisfies Record<ProductStatus, ProductStatusVariant>;

export const PRODUCT_CATEGORIES_MAP = Object.fromEntries(
  Object.values(ProductCategoryEnum).map(value => [
    value,
    {
      title: getHumanReadableString(value),
      value,
    },
  ])
) as Record<ProductCategoryEnum, { title: string; value: ProductCategoryEnum }>;

const CUSTOM_SUBCATEGORY_TITLES: Partial<Record<ProductSubCategoryEnum, string>> = {
  SERVICE_GENERAL: 'General Services',
  MISCELLANEOUS_EXEMPT: 'Miscellaneous Tax Exempt',
  DISCOUNT: 'Discounts',
  DIGITAL_GENERAL: 'General Digital',
  B2C_SAAS: 'B2C SaaS',
  B2B_SAAS: 'B2B SaaS',
  IAAS: 'IaaS',
  PHYSICAL_GENERAL: 'General Physical',
  PARTS_PURCHASED_OPTIONAL_MAINTENANCE_CONTRACTS:
    'Parts Purchased for Use in Performing Service Under Optional Maintenance Contracts',
  UTILITIES_FUEL: 'Utilities & Fuel',
};

export const PRODUCT_SUBCATEGORIES_MAP = Object.fromEntries(
  Object.values(ProductSubCategoryEnum).map(value => [
    value,
    {
      title: CUSTOM_SUBCATEGORY_TITLES[value] ?? getHumanReadableString(value),
      value,
    },
  ])
) as Record<ProductSubCategoryEnum, { title: string; value: ProductSubCategoryEnum }>;
