import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createRegistrationApi, REGISTRATION_STATE_KEY } from 'apis/registration-apis';
import Button from 'component-library/components/ButtonTmp/button';
import DatePicker from 'component-library/components/date-picker/date-picker';
import FormLabel from 'component-library/components/FormLabel';
import ModalPopup from 'component-library/components/Modal/modal-popup';
import Select from 'component-library/components/Select/Select';
import Tooltip from 'component-library/components/Tooltiptmp/tooltip';
import useToast from 'component-library/hooks/useToast';
import { InfoFilled } from 'components/icons';
import KCustomSelect from 'components/KCustomSelect';
import { isBefore, parseISO, startOfMonth } from 'date-fns';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useLocationData } from 'hooks/useLocationData';
import { useOrg } from 'hooks/useOrg';
import { useStateOptions } from 'hooks/useStateOptions';
import useTracking from 'hooks/useTracking';
import { ChangeEvent, useEffect, useState } from 'react';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';
import { LocationOptionType } from 'types/location';
import { RegistrationStatus } from 'types/shared-types';
import { getFormattedDate } from 'utils/dates';
import { array, date, object, string } from 'yup';

const baseValidationSchema = object().shape({
  country_code: string().required('Country is required'),
  state_code: string().required('State is required'),
  registration_date: date().required('Registration date is required'),
  registration_email: string().email('Invalid email address').required('Email is required'),
  filing_frequency: string().required('Filing frequency is required'),
  filing_days: string().required('Filing days is required'),
  username: string()
    .nullable()
    .when('is_checkbox_checked', (isChecked: boolean[], schema) => {
      return isChecked[0] ? schema.nullable() : schema.required('Username is required');
    }),
  password_plain_text: string()
    .nullable()
    .when('is_checkbox_checked', (isChecked: boolean[], schema) => {
      return isChecked[0] ? schema.nullable() : schema.required('Password is required');
    }),
  security_questions: array().notRequired(),
});

const SALES_TAX_FREE_STATES = ['DE', 'MT', 'NH', 'OR'];

export const RegistrationForm = () => {
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const [securityQuestions, setSecurityQuestions] = useState([{ question: '', answer: '' }]);
  const [showOptions, setShowOptions] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const isRequestRegistrationFormOpen = searchParams.get('importRegistration') === 'true';

  const { countries } = useLocationData();
  const { stateOptions, fetchStateOptions } = useStateOptions({ formatter: 'ca_taxJurisdiction' });

  const validationSchema = baseValidationSchema.shape({
    create_filing_option: showOptions
      ? string()
          .oneOf(['create_only_new_filing_periods', 'additionally_create_past_filing_periods'])
          .required('Filing option is required')
      : string().oneOf(['create_only_new_filing_periods', 'additionally_create_past_filing_periods']),
  });

  const mutation = useMutation({
    mutationFn: async (payload: any) => {
      const { data } = await createRegistrationApi(orgId, payload);
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [REGISTRATION_STATE_KEY] });
      onCloseModal();
      handleSuccessNotification('Registration added successfully.');
    },
    onError: (err: any) => {
      if (err && err.response?.status === 409) {
        toast({
          title: 'Registration for state and country already exists',
          status: 'error',
        });
        return;
      }
      handleFailNotification(err);
    },
  });

  const formik = useFormik({
    initialValues: {
      country_code: 'US',
      state_code: '',
      state_name: '',
      registration_date: '',
      registration_email: '',
      filing_frequency: '',
      filing_days: '',
      status: RegistrationStatus.VALIDATING,
      username: '',
      password_plain_text: '',
      password_metadata_plain_text: '',
      create_filing_option: '',
      imported: true,
      is_checkbox_checked: false,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      let payload = { ...values } as any;
      if (SALES_TAX_FREE_STATES.includes(payload.state_code)) {
        toast({
          title: `${payload.state_name} Doesn't have sales tax. Please choose other state.`,
          status: 'warning',
          duration: 5000,
        });
      } else {
        switch (values.create_filing_option) {
          case 'create_only_new_filing_periods':
            payload = {
              create_filings_from: getFormattedDate(new Date(), 'yyyy-MM-dd'),
              ...payload,
            };
            break;
          case 'additionally_create_past_filing_periods':
            payload = {
              create_filings_from: values.registration_date,
              ...payload,
            };
            break;
        }

        mutation.mutate(payload);
        track('registers jurisdiction', { jurisdiction: values.state_name });
      }
    },
  });

  useEffect(() => {
    if (formik.values.country_code) {
      fetchStateOptions(formik.values.country_code);
    }
  }, [formik.values.country_code, fetchStateOptions]);

  const { track } = useTracking();

  const handleFilingChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const FilingOptions = [
      { value: 'MONTHLY', label: '20' },
      { value: 'QUARTERLY', label: '20' },
      { value: 'SEMI_ANNUALLY', label: '20' },
      { value: 'ANNUALLY', label: '20' },
    ];
    const selectedFilingFreq = event.target.value;
    formik.setFieldValue('filing_frequency', selectedFilingFreq);
    const selectedFiling = FilingOptions.find(filing => filing.value === selectedFilingFreq);

    if (selectedFiling) {
      formik.setFieldValue('filing_days', selectedFiling.label);
    } else {
      formik.setFieldValue('filing_days', '');
    }
  };

  const handleAddQuestion = () => {
    if (securityQuestions.length < 3) {
      setSecurityQuestions([...securityQuestions, { question: '', answer: '' }]);
    }
  };

  const handleQuestionChange = (index: number, field: 'question' | 'answer', value: string) => {
    const updatedQuestions = [...securityQuestions];
    updatedQuestions[index][field] = value;
    setSecurityQuestions(updatedQuestions);
    formik.setFieldValue('password_metadata_plain_text', JSON.stringify(updatedQuestions));
  };

  const closeModal = () =>
    setSearchParams(params => {
      params.delete('importRegistration');
      return params;
    });

  const onCloseModal = () => {
    formik.resetForm();
    setSecurityQuestions([{ question: '', answer: '' }]);
    setShowOptions(false);
    closeModal();
  };

  const checkIfDateBeforeCurrentMonth = (date: string) => {
    const parsedDate = parseISO(date);
    const startOfCurrentMonth = startOfMonth(new Date());
    const isBeforeCurrentMonth = isBefore(parsedDate, startOfCurrentMonth);
    setShowOptions(isBeforeCurrentMonth);
  };

  return (
    <ModalPopup
      closeOnOverlayClick={false}
      size={'lg'}
      isOpen={isRequestRegistrationFormOpen}
      onClose={onCloseModal}
      header={'Import Registration'}
      footer={
        <Flex gap={2}>
          <Button variant={'outline'} color={'secondary'} onClick={onCloseModal}>
            Cancel
          </Button>
          <Button
            variant={'solid'}
            color={'primary'}
            width={'90px'}
            isLoading={mutation.isPending}
            onClick={() => formik.handleSubmit()}
          >
            Save
          </Button>
        </Flex>
      }
    >
      <Stack spacing={'0px'} gap={'16px'}>
        <Text>Only import a Registration if you&apos;ve already registered outside Kintsugi</Text>
        <HStack align={'baseline'}>
          <FormControl isRequired>
            <FormLabel htmlFor="country_code">Country</FormLabel>
            <KCustomSelect
              id="country_code"
              name="country_code"
              itemTitle="label"
              itemValue="value"
              items={countries}
              value={formik.values.country_code}
              onChange={value => {
                formik.setFieldValue('state_code', '');
                formik.setFieldValue('country_code', value);
              }}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="state_code">State</FormLabel>
            <KCustomSelect
              id="state_code"
              name="state_code"
              items={stateOptions}
              itemTitle="label"
              itemValue="value"
              returnItem={true}
              value={formik.values.state_code}
              onChange={selectedItem => {
                const item = selectedItem as LocationOptionType;
                formik.setFieldValue('state_name', item.label);
                formik.setFieldValue('state_code', item.value);
              }}
            />
            {formik.errors.state_code && formik.touched.state_code && (
              <Text color={'#E53E3E'}>{formik?.errors?.state_code?.toString()}</Text>
            )}
          </FormControl>
        </HStack>
        <HStack align={'baseline'}>
          <FormControl isRequired>
            <FormLabel htmlFor="registration_date">Registration Date</FormLabel>
            <DatePicker
              selected={formik.values.registration_date}
              onChange={date => {
                formik.setFieldValue('registration_date', date);
                checkIfDateBeforeCurrentMonth(date);
              }}
            />
            {formik.errors.registration_date && formik.touched.registration_date && (
              <Text color={'#E53E3E'}>{formik.errors.registration_date}</Text>
            )}
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="registration_email">Registration Email</FormLabel>
            <Input
              id="registration_email"
              type="email"
              name="registration_email"
              value={formik.values.registration_email}
              onChange={formik.handleChange}
            />
            {formik.errors.registration_email && formik.touched.registration_email && (
              <Text color={'#E53E3E'}>{formik.errors.registration_email}</Text>
            )}
          </FormControl>
        </HStack>
        {showOptions && (
          <FormControl as="fieldset" isRequired={showOptions}>
            <FormLabel>Select filings generation option :</FormLabel>
            <RadioGroup name="create_filing_option" value={formik.values.create_filing_option}>
              <Stack direction="column">
                <Radio value={'create_only_new_filing_periods'} onChange={formik.handleChange}>
                  Create filings only for the upcoming periods
                </Radio>
                <Radio value={'additionally_create_past_filing_periods'} onChange={formik.handleChange}>
                  Create filings for all the periods from the registration date
                </Radio>
              </Stack>
            </RadioGroup>
            {formik.errors.create_filing_option && formik.touched.create_filing_option && (
              <Text color={'#E53E3E'}>{formik.errors.create_filing_option}</Text>
            )}
          </FormControl>
        )}
        <FormControl isRequired>
          <FormLabel htmlFor="filing_frequency">Filing Frequency</FormLabel>
          <Select
            id="filing_frequency"
            name="filing_frequency"
            value={formik.values.filing_frequency}
            onChange={handleFilingChange}
          >
            <option>Select filing frequency</option>
            <option value="MONTHLY">Monthly</option>
            <option value="QUARTERLY">Quarterly</option>
            <option value="SEMI_ANNUALLY">Semi-annually</option>
            <option value="ANNUALLY">Annually</option>
          </Select>
          {formik.errors.filing_frequency && formik.touched.filing_frequency && (
            <Text color={'#E53E3E'}>{formik.errors.filing_frequency}</Text>
          )}
        </FormControl>
        <HStack align={'baseline'} display={formik.values.is_checkbox_checked ? 'none' : 'flex'}>
          <FormControl isRequired={!formik.values.is_checkbox_checked}>
            <HStack gap={0}>
              <FormLabel htmlFor="username">Username</FormLabel>
              <Box ml={-2}>
                <Tooltip
                  shouldWrapChildren
                  label={`The username that is used to login this jurisdictions website, this could be your email address or a username.`}
                >
                  <InfoFilled color="#383D58" width="16" height="16" />
                </Tooltip>
              </Box>
            </HStack>

            <Input id="username" name="username" value={formik.values.username} onChange={formik.handleChange} />
            {formik.errors.username && formik.touched.username && (
              <Text color={'#E53E3E'}>{formik.errors.username}</Text>
            )}
          </FormControl>
          <FormControl isRequired={!formik.values.is_checkbox_checked}>
            <HStack gap={0}>
              <FormLabel htmlFor="password_plain_text">Password</FormLabel>
              <Box ml={-2}>
                <Tooltip shouldWrapChildren label={`The password you use to log into this jurisdiction's website.`}>
                  <InfoFilled color="#383D58" width="16" height="16" />
                </Tooltip>
              </Box>
            </HStack>
            <InputGroup>
              <Input
                id="password_plain_text"
                name="password_plain_text"
                type={showPassword ? 'text' : 'password'}
                value={formik.values.password_plain_text}
                onChange={formik.handleChange}
              />
              <InputRightElement cursor="pointer" onClick={() => setShowPassword(!showPassword)}>
                <Icon as={showPassword ? MdOutlineVisibility : MdOutlineVisibilityOff} w={5} h={5} color="gray.500" />
              </InputRightElement>
            </InputGroup>
            {formik.errors.password_plain_text && formik.touched.password_plain_text && (
              <Text color={'#E53E3E'}>{formik.errors.password_plain_text}</Text>
            )}
          </FormControl>
        </HStack>
        {securityQuestions.map((question, index) => (
          <Box key={index}>
            <FormControl>
              <FormLabel htmlFor={`question_${index + 1}`}>Question {index + 1}</FormLabel>
              <Input
                placeholder="Question"
                id={`question_${index + 1}`}
                value={question.question}
                onChange={e => handleQuestionChange(index, 'question', e.target.value)}
              />
            </FormControl>
            <FormControl mt={2}>
              <Input
                placeholder="Answer"
                id={`answer_${index + 1}`}
                value={question.answer}
                onChange={e => handleQuestionChange(index, 'answer', e.target.value)}
              />
            </FormControl>
          </Box>
        ))}
        {securityQuestions.length < 3 && (
          <Text color={'#4285F4'} onClick={handleAddQuestion} fontSize={'md'} fontWeight={500}>
            + &nbsp;Another Question
          </Text>
        )}
        {/* put checkbox separately as entire text is clickable */}
        <Flex gap={2}>
          <Checkbox
            isChecked={formik.values.is_checkbox_checked}
            onChange={formik.handleChange}
            name="is_checkbox_checked"
            isFocusable={false}
          ></Checkbox>
          <Text mt={'1px'} fontSize={'14px'}>
            I don&apos;t have the login credentials for this jurisdiction. I authorize Kintsugi to retrieve them on my
            behalf and initiate the Power of Attorney process, if required.
          </Text>
        </Flex>
      </Stack>
    </ModalPopup>
  );
};
