import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import Button from 'component-library/components/ButtonTmp/button';
import DatePicker from 'component-library/components/date-picker/date-picker';
import Select from 'component-library/components/Select/Select';
import { useLocationData } from 'hooks/useLocationData';
import { useStateOptions } from 'hooks/useStateOptions';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { ChangeEvent, useEffect, useState } from 'react';
import { MdOutlineArrowDropDown } from 'react-icons/md';

type FilingFilterProps = {
  tableFilters: UseTableFiltersType;
  onClose: () => void;
};

const FilingFilter = ({ onClose, tableFilters }: FilingFilterProps) => {
  const { countries } = useLocationData();
  const { stateOptions, fetchStateOptions } = useStateOptions();
  const { start_date, end_date, state_code, country_code, setFilters, resetFilters } = tableFilters;
  const [accordionIndex, setAccordionIndex] = useState<number | number[]>([]);

  const [startDate, setStartDate] = useState<string | undefined>(start_date);
  const [endDate, setEndDate] = useState<string | undefined>(end_date);
  const [selectedState, setSelectedState] = useState<string | undefined>(state_code);
  const [selectedCountry, setSelectedCountry] = useState<string | undefined>(country_code);

  useEffect(() => {
    if (selectedCountry) {
      fetchStateOptions(selectedCountry);
    }
  }, [selectedCountry, fetchStateOptions]);

  const onCountryChange = async (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedCountry(e.target.value);
    setSelectedState(undefined);
  };

  const handleSave = () => {
    setFilters({
      state_code: selectedState || undefined,
      start_date: startDate || undefined,
      end_date: endDate || undefined,
      country_code: selectedCountry || undefined,
    });

    onClose();
  };

  const handleClear = () => {
    resetFilters();
    onClose();
  };

  const hasFilter = selectedState || startDate || endDate || selectedCountry;

  return (
    <Stack>
      <HStack justifyContent={'space-between'}>
        <Text fontSize={'18px'} fontWeight="medium">
          Filters
        </Text>
        {hasFilter && (
          <Text fontSize={'12px'} color={'#4285F4'} cursor={'pointer'} onClick={handleClear}>
            Clear All Filters
          </Text>
        )}
      </HStack>
      <Accordion allowMultiple variant={'filter'} index={accordionIndex} onChange={index => setAccordionIndex(index)}>
        <AccordionItem>
          <AccordionButton>
            <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
              Country
            </Box>
            <AccordionIcon as={MdOutlineArrowDropDown} fontSize={'1.5rem'} />
          </AccordionButton>
          <AccordionPanel mb={2}>
            <Select value={selectedCountry} onChange={onCountryChange} placeholder="Select">
              {countries.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
              Jurisdiction
            </Box>
            <AccordionIcon as={MdOutlineArrowDropDown} fontSize={'1.5rem'} />
          </AccordionButton>
          <AccordionPanel mb={2}>
            <Select value={selectedState} onChange={e => setSelectedState(e.target.value)} placeholder="Select">
              {stateOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
              Date
            </Box>
            <AccordionIcon as={MdOutlineArrowDropDown} fontSize={'1.5rem'} />
          </AccordionButton>
          <AccordionPanel mb={4}>
            <Text fontWeight="medium" mb={2}>
              Start Date
            </Text>
            <DatePicker selected={startDate} onChange={date => setStartDate(date)} placeholderText="Select" />
            <Text fontWeight="medium" mt={4} mb={2}>
              End Date
            </Text>
            <DatePicker selected={endDate} onChange={date => setEndDate(date)} placeholderText="Select" />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <HStack direction="row" justifyContent={'space-around'} spacing="18px">
        <Button variant="outline" color="secondary" onClick={onClose} width={'132px'} height={'32px'}>
          Cancel
        </Button>
        <Button
          variant="solid"
          color="primary"
          onClick={handleSave}
          width={'132px'}
          height={'32px'}
          isDisabled={!hasFilter}
        >
          Apply
        </Button>
      </HStack>
    </Stack>
  );
};

export default FilingFilter;
