export const convertPxToRem = (pxValue = 0) => `${pxValue * 0.0625}rem`;

export const typography = {
  fonts: {
    heading: "'Roboto', sans-serif",
    body: "'Roboto', sans-serif",
  },
  fontSizes: {
    xxs: convertPxToRem(10),
    xs: convertPxToRem(12),
    sm: convertPxToRem(14),
    md: convertPxToRem(16),
    lg: convertPxToRem(18),
    xl: convertPxToRem(20),
    '2xl': convertPxToRem(24),
    '3xl': convertPxToRem(28),
    '4xl': convertPxToRem(36),
    '5xl': convertPxToRem(48),
    '6xl': convertPxToRem(64),
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
  },
  lineHeights: {
    normal: 'normal',
    none: '1',
    shorter: '1.25',
    short: '1.375',
    base: '1.5',
    tall: '1.625',
    taller: '2',
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
};
