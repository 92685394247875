import { getHumanReadableString } from 'utils/enum-helpers';

import { NexusInstance } from './shared-types';

export type FilteredNexus = {
  exposed: NexusInstance[];
  non_exposed: NexusInstance[];
  isExposedNexusWaiting: boolean;
  isNonExposedNexusWaiting: boolean;
};

export enum PhysicalNexusCategoryEnum {
  TELECOMMUTING_OR_REMOTE_EMPLOYEE = 'TELECOMMUTING_OR_REMOTE_EMPLOYEE',
  WARRANTY_AND_REPAIR_SERVICES = 'WARRANTY_AND_REPAIR_SERVICES',
  CATALOGUE_DISTRIBUTION_OR_ADVERTISING_MATERIAL = 'CATALOGUE_DISTRIBUTION_OR_ADVERTISING_MATERIAL',
  DELIVERY_BY_COMMON_CARRIER = 'DELIVERY_BY_COMMON_CARRIER',
  DELIVERY_BY_OWN_VEHICLES = 'DELIVERY_BY_OWN_VEHICLES',
  IN_STATE_SALES_PERSON = 'IN_STATE_SALES_PERSON',
  INDEPENDENT_CONTRACTOR_OR_THIRD_PARTY_SALES_PERSON = 'INDEPENDENT_CONTRACTOR_OR_THIRD_PARTY_SALES_PERSON',
  WAREHOUSE_AND_INVENTORY_PRESENCE = 'WAREHOUSE_AND_INVENTORY_PRESENCE',
  PHYSICAL_BUSINESS_LOCATION = 'PHYSICAL_BUSINESS_LOCATION',
  EMPLOYEES_AGENTS_CONTRACTORS = 'EMPLOYEES_AGENTS_CONTRACTORS',
  OWN_LEASE_A_PROPERTY = 'OWN_LEASE_A_PROPERTY',
  INVENTORY = 'INVENTORY',
  DELIVERY_USING_COMPANY_VEHICLES = 'DELIVERY_USING_COMPANY_VEHICLES',
  OWN_BUSINESS_INFRASTRUCTURE_AND_EQUIPMENT = 'OWN_BUSINESS_INFRASTRUCTURE_AND_EQUIPMENT',
}

const CUSTOM_PHYSICAL_NEXUS_CATEGORY_TITLES: Partial<Record<PhysicalNexusCategoryEnum, string>> = {
  OWN_LEASE_A_PROPERTY: 'Own/Lease a Property',
  EMPLOYEES_AGENTS_CONTRACTORS: 'Employees/Agents/Contractors',
};

export const PHYSICAL_NEXUS_CATEGORIES_MAP = Object.fromEntries(
  Object.values(PhysicalNexusCategoryEnum).map(value => [
    value,
    {
      title: CUSTOM_PHYSICAL_NEXUS_CATEGORY_TITLES[value] || getHumanReadableString(value),
      value,
    },
  ])
) as Record<PhysicalNexusCategoryEnum, { title: string; value: PhysicalNexusCategoryEnum }>;

export type PhysicalNexusCategory = {
  name: string;
  title: string;
  description: string;
  example: string;
  is_category_assigned: boolean;
};
