import { OnboardingStatus } from '_api-client';

import api from './api';

const setOnboarding = (status: OnboardingStatus) => {
  return api.put(`/v1/onboarding`, {
    status,
  });
};

export { setOnboarding };
