import { Flex, HStack, Icon } from '@chakra-ui/react';
import { downloadAttachmentByAttachmentId } from 'apis/attachment';
import Text from 'component-library/components/Text';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { MdOutlineFileDownload } from 'react-icons/md';
import { getAttachmentIcon, getFileExtension } from 'utils';

type AttachmentCardProps = {
  attachmentName: string;
  attachmentId: string;
};

export const AttachmentCard = ({ attachmentName, attachmentId }: AttachmentCardProps) => {
  const { orgId } = useOrg();
  const { handleFailNotification } = useHandleNotification();
  const fileType = getFileExtension(attachmentName);

  const createDownloadLink = (blob: Blob, fileName: string): HTMLAnchorElement => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    return link;
  };

  const triggerDownload = (link: HTMLAnchorElement): void => {
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleAttachmentDownload = async (attachmentId: string) => {
    try {
      const response = await downloadAttachmentByAttachmentId(attachmentId, orgId);
      const blob = new Blob([response.data]);
      const link = createDownloadLink(blob, attachmentName);
      triggerDownload(link);
      URL.revokeObjectURL(link.href);
    } catch (error) {
      handleFailNotification(error);
    }
  };

  return (
    <Flex
      p={2.5}
      border={'1px solid'}
      justifyContent={'space-between'}
      alignItems={'center'}
      borderColor={'#EFEFF3'}
      borderRadius={'md'}
      gap={8}
      minW={'13.8rem'}
      maxW={'fit-content'}
      cursor={'pointer'}
      onClick={() => handleAttachmentDownload(attachmentId)}
    >
      <HStack gap={2.5}>
        <Icon as={getAttachmentIcon(fileType)} width={6} height={6} />
        <Text noOfLines={1}>{attachmentName}</Text>
      </HStack>
      <Icon as={MdOutlineFileDownload} width={6} height={6} />
    </Flex>
  );
};
