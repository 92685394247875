import { DefaultService, ValidationAddress } from '_api-client';
import { AddressInstance, AddressSuggestion, ApiResponse, TableFilter } from 'types/shared-types';

import api from './api';

const getInvalidAddresses = (orgId: string, page: number, size: number, filter: object) => {
  return api.get(`/v1/transactions/addresses`, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      status: 'INVALID',
      page: page,
      size: size,
      ...filter,
    },
  });
};

const getAddresses = (orgId: string, params?: TableFilter) => {
  return api.get<ApiResponse<AddressInstance>>(`/v1/transactions/addresses`, {
    headers: {
      'x-organization-id': orgId,
    },
    params,
  });
};

const getSuggestions = (payload: ValidationAddress) => {
  return DefaultService.proxyAddressValidationSuggestionsV1AddressValidationSuggestionsPost({
    requestBody: payload,
  });
};

const bulkSuggestionAddresses = (orgId: string, payload: AddressInstance[]) => {
  return api.post<AddressSuggestion[]>(`/v1/address_validation/bulk_suggestions`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const revalidateAddress = (orgId: string) => {
  return api.put(
    `/v1/transactions/addresses/revalidation`,
    {},
    {
      headers: {
        'x-organization-id': orgId,
      },
    }
  );
};

const blankAddressSuggestions = async (orgId: string, addressId: string) => {
  return await api.get(`/v1/transactions/addresses/blank/suggestion?address_id=${addressId}`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const updateBlankAddresses = async (orgId: string) => {
  return await api.post(
    `/v1/transactions/addresses/fill_blank`,
    {},
    {
      headers: {
        'x-organization-id': orgId,
      },
    }
  );
};

const ADDRESS_STATE_KEY = 'addresses';
export {
  ADDRESS_STATE_KEY,
  blankAddressSuggestions,
  bulkSuggestionAddresses,
  getAddresses,
  getInvalidAddresses,
  getSuggestions,
  revalidateAddress,
  updateBlankAddresses,
};
